




















































































import { Component, Vue } from 'vue-property-decorator';
import { IUDocumentosProfileUpdate } from '@/interfaces';
import {
  dispatchGetDocumentosCategorias,
  dispatchCreateDocumento,
  dispatchGetDocumentosTipos,
} from '@/store/admin/actions';
import {
  readAdminOneDocumento,
  readAdminDocumentoCategorias,
  readAdminDocumentoTipos,
} from '@/store/admin/getters';
import { apiUploadFolder } from '@/env';

@Component
export default class CreateDocumento extends Vue {
  public valid = true;
  public nombre: string = '';
  public descripcion: string = '';
  public categoria = null;
  public tipo = null;
  public urlDoc: string = '';
  public urlTipo: string = '';
  public file: any = null;
  public async mounted() {
    await dispatchGetDocumentosCategorias(this.$store);
    await dispatchGetDocumentosTipos(this.$store);

   // this.reset();
  }


  public url(u) {
    return apiUploadFolder + u;
  }
  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile = new FormData();
      if (this.nombre) {
        updatedProfile.append('nombre', this.nombre);
      }
      if (this.descripcion) {
        updatedProfile.append('descripcion', this.descripcion);
      }

      updatedProfile.append('id_categoria', String(this.categoria));
      updatedProfile.append('id_tipo', String(this.tipo));
      if (this.file) {
        updatedProfile.append('file', this.file);
      }
      await dispatchCreateDocumento(this.$store, {
        id: Number(this.$router.currentRoute.params.id),
        documento: updatedProfile,
      });
      this.$router.push('/main/admin/documentos/all');
    }
  }

  get documento() {
    return readAdminOneDocumento(this.$store)(
      +this.$router.currentRoute.params.id,
    );
  }
  get categorias() {
    return readAdminDocumentoCategorias(this.$store);
  }
  get tiposall() {
    return readAdminDocumentoTipos(this.$store);
  }
}
